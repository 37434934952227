import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom'; 

function BannerComponents() {
    const [banner, setBanner] = useState([]);

    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
        slugName = pathName.slice(1);
    }else{
        let slugArr = pathName.split('/', 2);
        slugName = slugArr.slice(1);
    }

    const getBannerApiData = async () => {
        if (!slugName) {
          const res = await axios.get('/banner');
          setBanner(res.data.recive_data.banner_data[0]);
          // console.log(res.data.recive_data);  
        } else {
          const res = await axios.get(`/banner?page_slug=${slugName}`);
          setBanner(res.data.recive_data.banner_data[0]);
          // console.log(res.data.recive_data.banner_data[0]);
        }
    }

    useEffect(() => {
        getBannerApiData();
      }, []);

      const bannerImg = banner.banner_image;
      const bannerBtn = banner.external_button_link;
      // console.log(bannerBtn);
      
    return (
        <>
              {
        !slugName ? (
          <section className="siteBanner">
            <div className="siteBanner__slider">
              <div className="siteBanner__slider_item">
                {/* <!-- <img src="assets/images/Banner-Slider-image.jpg" width="1920" height="876" alt="Slider One"> --> */}                               
                  {/* <img src="./images/homePage_banner.png" width="1920" height="930" alt="" /> */}
                  {
                    bannerImg?(<picture>
                          <source media="(min-width: 1024px)" srcSet={bannerImg.desktop_banner_image} />
                          <source media="(min-width: 768px)" srcSet={bannerImg.tab_banner_image} />
                          <source media="(min-width: 300px)" srcSet={bannerImg.mobile_banner_image} />
                          <img src={bannerImg.desktop_banner_image} width="1920" height="930" alt="" />
                          {/* <img src={bannerImg.desktop_banner_image} srcSet={`${bannerImg.mobile_banner_image} 300w, ${bannerImg.tab_banner_image} 768w, ${bannerImg.desktop_banner_image} 1024,`} 
                                sizes="(max-width: 768px) 768px, (max-width: 1024px) 768px, 1920px" /> */}
                        </picture>
                    ):null
                  }
                <div className="siteBanner__bannerOverlay d-flex align-items-center">
                  <div className="container">
                    <div className="bannerOverlay__center">
                      <h1 dangerouslySetInnerHTML={{__html: banner.title}}></h1>
                      <p dangerouslySetInnerHTML={{__html: banner.content}}></p>
                      {/* <a href="https://ccmh.ironglove.studio/about-us/" className="rmbttn">Contact Us</a> */}
                      {
                        bannerBtn?(
                          <NavLink to={'/' + bannerBtn[0].url}  className="rmbttn">{bannerBtn[0].title}</NavLink>
                          // bannerBtn.map((post, index) => {
                          //   const { title, url, target } = post;
                          //   <a href={url} className="rmbttn">gnghngchnghnj {title}</a>
                          // })
                        ):null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) :(
          <section className="siteBanner">
            <div className="siteBanner__slider">
              <div className="siteBanner__slider_item">
                {/* <!-- <img src="assets/images/Banner-Slider-image.jpg" width="1920" height="876" alt="Slider One"> --> */}
                {
                    bannerImg?(<picture>
                          <source media="(min-width: 1024px)" srcSet={bannerImg.desktop_banner_image} />
                          <source media="(min-width: 768px)" srcSet={bannerImg.tab_banner_image} />
                          <source media="(min-width: 300px)" srcSet={bannerImg.mobile_banner_image} />
                          <img src={bannerImg.desktop_banner_image} width="1920" height="930" alt="" />
                          {/* <img src={bannerImg.desktop_banner_image} srcSet={`${bannerImg.mobile_banner_image} 300w, ${bannerImg.tab_banner_image} 768w, ${bannerImg.desktop_banner_image} 1024,`} 
                                sizes="(max-width: 768px) 768px, (max-width: 1024px) 768px, 1920px" /> */}
                        </picture>
                    ):null
                  }
                <div className="siteBanner__bannerOverlay inner_bannerOverlay__center d-flex align-items-center inner_bannerOverlay__center">
                  <div className="container">
                    <div className="bannerOverlay__center">
                      <h1 dangerouslySetInnerHTML={{__html: banner.title}}></h1>
                      <p dangerouslySetInnerHTML={{__html: banner.content}}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>)
      }
        </>
    )
}

export default BannerComponents;