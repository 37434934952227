import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function PostComponents() {
    const [postdata, setPostdata] = useState([]);

    const getApiData = async() =>{
        const res = await axios.get('/post');
        setPostdata(res.data.recive_data.post_data);
        // console.log(res.data.recive_data.testimonial_data );  
    }
    useEffect( () => {
        getApiData();
    }, []);
  return (
    <section className="blog_info_sec margin-t-b">
        <div className="container">
            <h2>Recent Blogs</h2>
            <div className="row">
                {
                    postdata.slice(0, 3).map( (post, index) => {
                        const { add_image, title, publish_date } = post;
                        return(
                            <div className="col-12 col-md-4 col-lg-4" key={index}>
                                <div className="card">
                                    {
                                        add_image?(<img src={add_image.image_path} alt="..." />):(
                                            <img className="img-fluid" src="./images/no-image.jpg"  alt="" />
                                        )
                                    }
                                    <div className='publishDate'>
                                        <h5>{publish_date}</h5>
                                    </div>
                                    <div className="card-body">
                                        <h4>{title}</h4>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>

            <div className="blog_info_bun">
                <NavLink to="/blog" className="gg1btn">Read More </NavLink>
            </div>
        </div>
    </section>
  )
}

export default PostComponents;