import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from 'react-router-dom';

function TabComponents() {
    const [mydata, setMydata] = useState([]);

    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setMydata(res.data.recive_data[2].tab_content);
        // console.log(res.data.recive_data[2]); 
    }
    useEffect( () => {
        getApiData();
    }, []);

    // const tabBtn = mydata.add_button;
    // console.log(tabBtn);
  return (
    <section className="tab_section margin-t-b ">
        <div className="container">
            <h2>What We Do</h2>
            <Tabs
                id="controlled-tab-example"
                // onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                {
                    mydata?(
                    mydata.map((post, i) => {
                        const { image_details, header, content, add_button } = post;
                        return (
                            <Tab eventKey={'mytab' + i} title={header} key={i}>
                                <div className="row two_colom_img_textSec rightImg_leftcontent">
                                    <div className="col-md-6 content_div_sec" >
                                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                                        {
                                            add_button?(
                                                add_button.map( (post, index) => {
                                                    const { button} = post;
                                                    return(
                                                        <NavLink to={button.url} className="gg2btn" key={index} >{button.title}</NavLink>
                                                    )
                                                })
                                            ):null
                                        }
                                    </div>
                                    <div className="col-md-6 img_div_sec">  
                                        {
                                            image_details ?(
                                                <img src={image_details.image_path} /> 
                                            ): null
                                        }
                                    </div>
                                </div>
                            </Tab>
                        );
                    })
                   ):null
                } 
            </Tabs>
        </div>
    </section>
  )
}

export default TabComponents