import React, { useEffect, useState } from 'react';
import axios from 'axios';

function TestimonialComponents() {
    const [datas, setDatas] = useState([]);

    const getApiData = async() =>{
        const res = await axios.get('/testimonial');
        setDatas(res.data.recive_data.testimonial_data);
        // console.log(res.data.recive_data.testimonial_data );  
    }
    useEffect( () => {
        getApiData();
    }, []);

  return (
    <section className="testimonial_sec margin-t-b">
        <div className="container">
            <h2>Testimonial</h2>
            <div className="row">
                {
                    datas.map( (post, index) => {
                        const { content, designation, name, add_image } = post;
                        return(
                        <div className="col-12 col-md-4 col-lg-4" key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="star_sec">
                                        <img src="./images/star.png" alt="" />
                                    </div>
        
                                    <p>{content}</p>
                                    <ul>
                                        <li><img src={add_image.image_path} alt="" /></li>
                                        <li>
                                            <strong>{name}</strong>
                                            <p>{designation}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
        
                        </div>
                        );
                    })
                }
                {/* <div className="col-12 col-md-4 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="star_sec">
                                <img src="./images/star.png" alt="" />
                            </div>

                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form, by injected need to be sure there. Nunc dictum
                                tristique lectus
                                commodo magna nec.
                            </p>
                            <ul>
                                <li><img src="./images/testi.png" alt="" /></li>
                                <li>
                                    <strong>Rosa A. Wimberly</strong>
                                    <p>1535 Lee Avenue</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="col-12 col-md-4 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="star_sec">
                                <img src="./images/star.png" alt="" />
                            </div>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form, by injected need to be sure there. Nunc dictum
                                tristique lectus
                                commodo magna nec.</p>
                            <ul>
                                <li><img src="./images/testi.png" alt="" /></li>
                                <li>
                                    <strong>Rosa A. Wimberly</strong>
                                    <p>1535 Lee Avenue</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="col-12 col-md-4 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="star_sec">
                                <img src="./images/star.png" alt="" />
                            </div>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form, by injected need to be sure there. Nunc dictum
                                tristique lectus
                                commodo magna nec.</p>
                            <ul>
                                <li><img src="./images/testi.png" alt="" /></li>
                                <li>
                                    <strong>Rosa A. Wimberly</strong>
                                    <p>1535 Lee Avenue</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div> */}

            </div>




        </div>

    </section>
  )
}

export default TestimonialComponents;