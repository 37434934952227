import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
// import Navbars from './Navbars';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const PostDetailsLayout = () => {
    const [postdata, setPostdata] = useState([]);
    const [postDetailsData, setPostDetailsData] = useState([]);

    const pathName = window.location.pathname;
    const slugArr = pathName.split('/', 3);
    const slugName = slugArr.slice(2);

    // alert(slugName);
    const getApiData = async () => {
        const res = await axios.get(`/post`);
        setPostdata(res.data.recive_data.post_data);

        const postres = await axios.get(`/postdetails?post_type=post&slug=${slugName}`);
        setPostDetailsData(postres.data.recive_data.post_details_data);
        // console.log(`/postdetails?post_type=post&slug=${slugName}`);  
    }

    useEffect(() => {
        getApiData();
    }, []);
    return (
        <>
            <SeoComponents />
            <BannerComponents />
            <section className="blog_details_sec margin-t-b">
                <div className="container">
                    <div className="row">
                        <div className="col-12 blog_details_left_sec">
                            {
                                postDetailsData?(
                                    <div className='postContent' dangerouslySetInnerHTML={{__html: postDetailsData.description}}></div>
                                ):null
                            }
                        </div>

                        {/* <div className="col-12 col-md-6 col-lg-4 blog_details_right_sec">
                            <h3>RECENT POSTS</h3>
                            <ul>
                                {
                                    postdata.map((post, index) => {
                                        const { title, post_slug } = post;
                                        return (
                                            <li key={index}>
                                                <NavLink to={"/blog/"+post_slug}  >{title}</NavLink>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default PostDetailsLayout