import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function TwoColComponent() {
    const [datas, setDatas] = useState([]);
    
    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setDatas(res.data.recive_data[0]);
        // console.log(res.data.recive_data[0]);  
    }
    useEffect( () => {
        getApiData();
    }, []);
    
    const data = datas.two_column_image_content;
    // const imgPath = data.image_details;
    // const databtn = data.button_link;
    // console.log(databtn);
  return (
    <section className="two_colom_img_textSec leftImg_rightContent margin-t-b">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 img_div_sec">
                    {
                        data?(<img src={data.image_details.image_path} alt="" /> ):null
                    }
                </div>
                <div className="col-12 col-md-6 col-lg-6 content_div_sec">
                    {
                        data?(<div dangerouslySetInnerHTML={{__html: data.content}}></div>):null
                    }
                    {/* <a href="" className="gg2btn">Get In Touch</a> */}
                    <NavLink to='/contacts' className="gg2btn">Get In Touch</NavLink>
                    {/* {
                        databtn?(
                            databtn.map( (post, index) => {
                                const { button } = post;
                                return(
                                    // <a href="" className="gg2btn">{titles}</a>
                                    <NavLink to={'/' + button.url} className="gg2btn">{button.title}</NavLink> 
                                );
                            })                                                     
                        ):null
                    } */}
                </div>
            </div>
        </div>
    </section>
  )
}

export default TwoColComponent;