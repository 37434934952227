import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function WebDesignComponents() {
    const [mydata, setMydata] = useState([]);

    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setMydata(res.data.recive_data[6].two_column_image_content);
        // console.log(res.data.recive_data[6]); 
    }
    useEffect( () => {
        getApiData();
    }, []);

    // let data = mydata.two_column_image_content;
    // let dataBtn = mydata.button_link;
  return (
    <section className="two_colom_img_textSec rightImg_leftcontent margin-t-b">
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 content_div_sec">
                {
                    mydata?(<div dangerouslySetInnerHTML={{__html: mydata.content}}></div>):null
                }
                {/* {
                    dataBtn?(
                        dataBtn.map( (post, index) => {
                            const { button} = post;
                            return(
                                <NavLink to={'/'+button.url} className="gg2btn" key={index} >{button.title}</NavLink>
                            )
                        })
                    ):null
                } */}
               
            </div>
            <div className="col-12 col-md-6 col-lg-6 img_div_sec">
                {
                    mydata.image_details?(<img src={mydata.image_details.image_path} alt="" /> ):null
                }
            </div>
        </div>
    </div>
</section>
  )
}

export default WebDesignComponents;