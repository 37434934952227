import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import Navbars from './Navbars';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const ExpertiseLayout = () => {
    const [expertise, setExpertise] = useState([]);

    const pathName = window.location.pathname;
    const slugName = pathName.slice(1);

    const getApiData = async () => {
      const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
      setExpertise(res.data.recive_data[0].flexible_column_content);
      // console.log(res.data.recive_data[0]);  
    }
    useEffect(() => {
      getApiData();
    }, []);

  return (
    <>
        <SeoComponents />
        <BannerComponents />
        <section className='expertise_section margin-t-b'>
            <div className="container">
                {
                    expertise?(<div className="fullWidth_content" dangerouslySetInnerHTML={{ __html: expertise.content }}></div>):null
                }
                
            </div>
        </section>
    </>
  )
}

export default ExpertiseLayout