import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
// import Navbars from './Navbars';
import BestfitComponents from '../components/BestfitComponents';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const ServicesLayout = () => {
  const [fullContent, setFullContent] = useState([]);
  const [tabAcc, setTabAcc] = useState([]);
  const [cardBox, setCardBox] = useState([]);

  const pathName = window.location.pathname;
  const slugName = pathName.slice(1);

  const getApiData = async () => {
    const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
    setFullContent(res.data.recive_data[0])
    setTabAcc(res.data.recive_data[1].tab_with_accordion);
    setCardBox(res.data.recive_data[3].card_box);
    // console.log(res.data.recive_data[5]);  
  }
  useEffect(() => {
    getApiData();
  }, []);
  // console.log(cardBox);
  const fulldata = fullContent.flexible_column_content;
  // const accordion = tabAcc.tab_with_accordion;

  return (
    <>
      <SeoComponents />
      <BannerComponents />
      <section className='servicesTab_section margin-t-b'>
        <div className="container">
          {
            fulldata ? (<div className="fullwidth_content" dangerouslySetInnerHTML={{ __html: fulldata.content }}></div>) : null
          }
        </div>

        <section className="tab_section">
          <div className="container">
            <Tabs
              id="controlled-tab-example"    
              // onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              {
                tabAcc ? (
                  tabAcc.map((post, i) => {
                    const { image_details, tab_header,add_icon, accordion } = post;
                    return (
                      <Tab eventKey={'mytab' + i} title={tab_header} key={i}>
                        <div className="row two_colom_img_textSec rightImg_leftcontent">
                          <div className="col-md-6 img_div_sec">
                            {
                              image_details ?(
                              <img src={image_details.image_path} />
                              ): null
                            }
                          </div>
                          <div className="col-md-6 content_div_sec" >
                            <Accordion defaultActiveKey="0">
                              {
                                accordion ? (
                                  accordion.map((post, accorindex) => {
                                    const { title, content } = post;
                                    return (
                                      <Accordion.Item eventKey={`${accorindex}`} key={accorindex}>
                                        <Accordion.Header>{title}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: content }}>
                                          
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })
                                ) : null
                              }
                            </Accordion>
                          </div>
                        </div>
                      </Tab>
                    );
                  })
                ) : null
              }
            </Tabs>
          </div>
        </section>
      </section>
      <BestfitComponents />

      <section className="client_review_sec margin-t-b">
        <div className="container">
          <h2>See Why Our Clients Love Us.</h2>
          <div className="row">
            {
              cardBox ? (
                cardBox.map((posts, index) => {
                  const { image_details, title, name, content } = posts;
                  return (
                    <div className="col-12 col-md-6 col-lg-4" key={index} >
                      <div className="card">
                        <div className="img_sec">
                          <img src="./images/blue.png" alt="..." />
                        </div>
                        <div className="card-body">
                          <h5>{title}</h5>
                          <p>{content}</p>
                          <strong>{name}</strong>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : null
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesLayout;