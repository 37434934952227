import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function ThreeColComponents() {
    const [mydata, setMydata] = useState([]);
    
    const getApiData = async() =>{
        const res = await axios.get('/pagecontent');
        setMydata(res.data.recive_data[1]);
        // console.log(res.data.recive_data[1]); 
    }
    useEffect( () => {
        getApiData();
    }, []);

    const data = mydata.three_column_image_content;
  return (
    <section className="three_column_img_content_sec margin-t-b">
        <div className="container">
            <div className="row">
                {
                    data?(
                        data.map( (post, index) => {
                            const { image_details, title, sub_title, content } = post;
                            return(
                                <div className="col-12 col-md-4 col-lg-4" key={index}>
                                    <div className="card">
                                        {
                                            image_details?(<img src={image_details.image_path} />):null
                                        }
                                        <div className="card-body">
                                            <h4>{title}</h4>
                                            <strong dangerouslySetInnerHTML={{__html: sub_title}}></strong>
                                            <p>{content}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ):null
                }
            </div>
        </div>
    </section>
  )
}

export default ThreeColComponents