import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';


const SeoComponents = () => {
    const [seodata, setSeodata] = useState([]);

    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
        slugName = pathName.slice(1);
    }else{
        let slugArr = pathName.split('/', 2);
        slugName = slugArr.slice(1);
    }

    const getApiData = async() =>{
        if (!slugName) {
          const res = await axios.get('/seo');
          setSeodata(res.data.recive_data);
        //   console.log(res.data.recive_data);  
        } else {
          const res = await axios.get(`/seo?page_slug=${slugName}`);
          setSeodata(res.data.recive_data);
        //   console.log(res.data.recive_data.seo_data);
        } 
    }
    useEffect( () => {
        getApiData();
    }, []);

    // let datas;
    // if(seodata){
       const datas = seodata.seo_data;
    // }

  return (
    <>
        {
            datas?(
                <Helmet>
                    <title>{datas.meta_title}</title>
                    <meta name="description" content={datas.meta_description} />
                </Helmet>
            ):null
        }
    </>
  )
}

export default SeoComponents;